var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: { type: "index", align: "center", width: "100", label: "序号" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "sku_code",
          align: "center",
          label: "货号",
          "min-width": "160",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "creator_name",
          align: "center",
          "min-width": "120",
          label: "修改人",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.creator_name || "-") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "create_time",
          align: "center",
          label: "修改时间",
          "min-width": "130",
        },
      }),
      _c("el-table-column", {
        attrs: { label: "修改内容", width: "160", align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    class: { active: scope.row.id === _vm.details.id },
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleDetails(scope.row)
                      },
                    },
                  },
                  [_vm._v("查看详情")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }