<template>
  <el-dialog
    title="编辑商品"
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="60%"
    class="dialog"
    center
  >
    <div class="contents">
      <div class="infodata">
        <div>货号：{{ formData.sku_code }}</div>
        <div>供应商：{{ formData?.supplier?.title }}</div>
      </div>
      <el-form
        :model="formData"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="商品名称" prop="title">
          <el-input
            v-model="formData.title"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="商品品类" prop="first_cid">
          <!-- <el-select v-model="formData.first_cid" placeholder="请选择商品品类">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select> -->
          <el-cascader
            style="width: 100%"
            v-model="value"
            :disabled="(formData.first_cid==0||formData.cid==0)?false:(formData.cid_is_audit == 1||formData.buy_way==1||formData.is_sales_cycle == 1)"
            :options="options"
            :props="props"
            @change="handleChange"
            filterable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="品种/品牌" prop="category_tag_id" v-if="category_tag_list.length>0">
          <el-select
            v-model="formData.category_tag_id"
            :disabled="(formData.category_tag_id==0)?false:(formData.cid_is_audit == 1||formData.buy_way==1||formData.is_sales_cycle == 1)"
            placeholder="请选择品种/品牌"
            filterable 
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in category_tag_list"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="采购员" prop="buyer_id">
          <el-select
            v-model="formData.buyer_id"
            :disabled="(formData.buyer_id==0)?false:(formData.cid_is_audit == 1 ||formData.buy_way==1||formData.is_sales_cycle == 1)"
            placeholder="请选择采购员"
            filterable 
          >
            <el-option
              :label="item.fullname"
              :value="item.id"
              v-for="(item, index) in buyer_list"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="规格" prop="spec">
          <el-input
            v-model="formData.spec"
            type="textarea"
            maxlength="250"
            :disabled="formData.is_sales_cycle == 1"
            show-word-limit
            placeholder="请输入规格"
          ></el-input>
        </el-form-item>
        <el-form-item label="标签" prop="promotion_tag_id" v-if="type==1&&formData.creator_type!=1&&formData.creator_type!=2">
          <el-select
            v-model="promotion_tag_id"
            placeholder="请选择标签"
            multiple
         
          >
          <!-- @change="promotion_tag_id_change" -->
            <el-option
            @click.native="promotion_tag_id_change($event,item)"
              :label="item.name"
              :value="item.id+''"
              v-for="(item, index) in promotion_tag_list"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-alert
        title="请确保采购知晓更改事项，后台数据更改均会记录日志"
        type="warning"
        :closable="false"
        show-icon
      >
      </el-alert>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" @click="onHandleSubmit" :loading="loadingSubmit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
  
  <script>
export default {
  name: "Salegoods-edit-dialog",
  props: {
    type: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      dialogVisible: false,
      loadingSubmit: false,
      formData: {
        sku_code: "", //"SR0669956",
        title: "", //"补炖品111",
        first_cid: "", //"1",一级分类id
        cid: "", //"20",二级分类id
        category_tag_id: "", //"20",品种/品牌id
        buyer_id: "", //"18",采购id
        spec: "", //"dadadada",规格
        promotion_tag_id: "", //标签
      },
      formDatacopy: {
        sku_code: "", //"SR0669956",
        title: "", //"补炖品111",
        first_cid: "", //"1",一级分类id
        cid: "", //"20",二级分类id
        category_tag_id: "", //"20",品种/品牌id
        buyer_id: "", //"18",采购id
        spec: "", //"dadadada",规格
        promotion_tag_id: "", //标签
      },
      promotion_tag_id:[],
      datatips: [
        {
          name: "商品名称",
          field: "title",
        },
        {
          name: "一级分类",
          field: "first_cid",
        },
        {
          name: "二级分类",
          field: "cid",
        },
        {
          name: "品种/品牌",
          field: "category_tag_id",
        },
        {
          name: "采购",
          field: "buyer_id",
        },
        {
          name: "规格",
          field: "spec",
        },
        {
          name: "标签",
          field: "promotion_tag_id",
        },
      ],
      value: [],
      options: [],
      category_tag_list: [
        // {
        //   id: 0,
        //   name: "无",
        // },
      ],
      buyer_list: [],
      promotion_tag_list: [
        {
          id: 0,
          name: "无",
        },
      ],
      props: {
        value: "id", //	指定选项的值为选项对象的某个属性值	string	—	'value'
        label: "name", //	指定选项标签为选项对象的某个属性值	string	—	'label'
        children: "children", //	指定选项的子选项为选项对象的某个属性值
        disabled: "cid_is_audit",
      },
      rules: {
        title: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        first_cid: [
          { required: true, message: "请选商品品类", trigger: "change" },
        ],
        category_tag_id: [
          { required: true, message: "请选品种/品牌", trigger: "change" },
        ],
        buyer_id: [
          { required: true, message: "请选采购员", trigger: "change" },
        ],
        // spec: [
        //   { required: true, message: "请输入规格", trigger: "change" },
        // ],
        promotion_tag_id: [
          { required: true, message: "请选标签", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    handleChange(value) {
      console.log(value);
      this.formData.first_cid = value[0];
      this.formData.cid = value[1];
      //   this.formData.buyer_id = "";
      this.formData.category_tag_id = "";
      //   this.getproductbuyerlist({
      //     sku_code: this.formData.sku_code,
      //     cid: this.formData.cid,
      //   });
      this.options.forEach((el) => {
              if (el.id == this.formData.first_cid) {
                el.children.forEach((item) => {
                  if (item.id == this.formData.cid) {
                    this.category_tag_list = [
                      // { id: 0, name: "无" },
                      ...item.tags,
                    ];
                  }
                });
              }
            });

 
          

    },
    promotion_tag_id_change(e,item){
      // console.log(e,item,'22222222222222222')
      if(item.id!=0){
        //之前选择无然后再选择其他的时候去除无的选项
        this.promotion_tag_id  = this.promotion_tag_id.filter((item)=>{
  return String(item)!= '0';
})
      }
      if(this.promotion_tag_id.includes('0')){
        //包含无就直接有一个无没有其他的无就直接清空
        this.promotion_tag_id=['0']
      }
      
    },
    async onInitData(e) {
       this.getcategorylist(e.sku_code);
     },
    gettaglist(e) {
      this.$api.general.taglist({ logistic_business_id: e }).then((res) => {
        console.log(res, "标签接口");
        this.promotion_tag_list = [{ id: 0, name: "无" }, ...res.data];
      });
    },
    getcategorylist(e) {
      this.$api.general.categoriesList({ sku_code: e }).then((res) => {
        console.log(res, "商品分类");
        this.options = res.data;
        this.getinfo({sku_code:e})
      });
    },
    //获取详情
    getinfo(e){
      this.$api.general
        .productinfo({ sku_code: e.sku_code })
        .then((res) => {
          console.log(res, "res");
          this.formData = res.data;
          this.formDatacopy = JSON.parse(JSON.stringify(res.data));

//标签改为多选
this.promotion_tag_id=res.data.promotion_tag_id==0?['0']:res.data.promotion_tag_id.split(',');
//标签改为多选
if(res.data.creator_type==1||res.data.creator_type==2){
  this.promotion_tag_id=['0']
}
          this.gettaglist(res.data.logistic_business_id);
          //判断有没有能回显的一级品类
          if (res.data.first_cid == 0 || res.data.cid == 0) {
            this.value = [];
            // this.getproductbuyerlist({
            //   sku_code: res.data.sku_code,
            // });
          } else {
            this.value = [res.data.first_cid, res.data.cid];
            // this.getproductbuyerlist({
            //   sku_code: res.data.sku_code,
            //   cid: res.data.cid,
            // });
          }
          this.getproductbuyerlist({
            sku_code: res.data.sku_code,
          });
          //根据一级品类选择三级品种品牌
          if (this.value.length > 0) {
            let constitem = 0;
            let constitem2 = 0;
            this.options.forEach((el) => {
              //判断如果没有这个一级品类了要清空
              if (el.id == this.value[0]) {
                constitem = constitem + 1;
              }

              if (el.id == res.data.first_cid) {
                el.children.forEach((item) => {
                  //判断如果没有这个二级品类了要清空
                  if (item.id == this.value[1]) {
                    constitem2 = constitem2 + 1;
                  }

                  if (item.id == res.data.cid) {
                    this.category_tag_list = [
                      // { id: 0, name: "无" },
                      ...item.tags,
                    ];
                  }
                });
              }
            });

            setTimeout(() => {
              if (constitem == 0) {
                this.value[0] = "";
              }
              if (constitem2 == 0) {
                this.value[1] = "";
              }
            }, 100);
          }
          this.dialogVisible = true;
        });
    
    },
    getproductbuyerlist(e) {
      this.$api.general.productbuyerlist(e).then((res) => {
        console.log(res, "采购员");
        this.buyer_list = res.data;
        let constitem = 0;
        res.data.forEach((item) => {
          if (item.id == this.formData.buyer_id) {
            constitem = constitem + 1;
          }
        });
        setTimeout(() => {
          if (constitem == 0) {
            this.formData.buyer_id = "";
          }
        }, 100);
      });
    },
    onHandleClose() {
      this.dialogVisible = false;
    },
    contrast() {
      let name = "";
      this.datatips.forEach((item) => {
        if (this.formData[item.field] != this.formDatacopy[item.field]) {
          name = name + item.name + ",";
        }
      });
      let newStr = name.substring(0, name.length - 1);
      this.$confirm(
        !name ? "你没有修改字段" : `你修改了${newStr}字段, 是否保存?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => { 
          //如果是下架的标签永远传0
          if(this.type==2){
            this.formData.promotion_tag_id='0'
          }
          this.$api.general
            .productedit(this.formData)
            .then((res) => {
              this.dialogVisible = false;
              this.loadingSubmit = false;
              this.$emit("tablechange", true);
            })
            .catch((err) => {
              this.loadingSubmit = false;
            });
        })
        .catch(() => {
          this.loadingSubmit = false;
        });
    },

    onHandleSubmit() {
      this.loadingSubmit = true;
      if(this.promotion_tag_id.includes(0)){
        //包含无就直接有一个无没有其他的无就直接清空
        this.formData.promotion_tag_id = '0';
      }else{
        this.formData.promotion_tag_id = this.promotion_tag_id.join(",");
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.contrast();
        } else {
          this.loadingSubmit = false;
        }
      });
    },
  },
  created() {},

  watch: {
    /**
     * 图片发生改变
     */
    category_tag_list() {
      let constitem = 0;
      this.category_tag_list.forEach((item) => {
        if (item.id == this.formData.category_tag_id) {
          constitem = constitem + 1;
        }
      });
      setTimeout(() => {
        if (constitem == 0) {
          this.formData.category_tag_id = "";
        }
      }, 100);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.infodata {
  display: flex;
  align-items: center;
  gap: 40px;
  font-size: 16px;
  font-weight: 600;
  padding-left: 70px;
  margin-bottom: 20px;
  box-sizing: border-box;
}
</style>
  