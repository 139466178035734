<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column type="index" align="center" width="100" label="序号">
    </el-table-column>
    <el-table-column
      prop="sku_code"
      align="center"
      label="货号"
      min-width="160"
    >
    </el-table-column>
    <el-table-column
      prop="creator_name"
      align="center"
      min-width="120"
      label="修改人"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        {{ scope.row.creator_name || "-" }}
      </template>
    </el-table-column>
    <el-table-column
      prop="create_time"
      align="center"
      label="修改时间"
      min-width="130"
    >
    </el-table-column>
    <el-table-column label="修改内容" width="160" align="center">
      <template slot-scope="scope">
        <el-button
          type="text"
          @click="onHandleDetails(scope.row)"
          :class="{ active: scope.row.id === details.id }"
          >查看详情</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "TableList",
  props: {
    details: {
      type: Object,
      default: () => {},
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * 查看详情
     */
    onHandleDetails(row) {
      this.$emit("on-details", row);
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: $danger-color;
}
</style>
