var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "商品变更记录",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "70%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
          staticClass: "contents",
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("table-list", {
                attrs: { tableData: _vm.tableData, details: _vm.goodsDetail },
                on: { "on-details": _vm.onHandelDetails },
              }),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      type: "primary",
                      "current-page": _vm.formData.page,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.formData.pageSize,
                      total: _vm.total,
                      layout: "total, sizes, prev, pager, next, jumper",
                      background: "",
                    },
                    on: {
                      "size-change": _vm.onHandleSizeChange,
                      "current-change": _vm.onHandleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.detailsVisible
            ? _c(
                "div",
                { staticClass: "detail" },
                [
                  _c("goods-details", {
                    attrs: {
                      loading: _vm.detailsLoading,
                      details: _vm.goodsDetail,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("返 回"),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }