var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "编辑商品",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "contents" },
            [
              _c("div", { staticClass: "infodata" }, [
                _c("div", [_vm._v("货号：" + _vm._s(_vm.formData.sku_code))]),
                _c("div", [
                  _vm._v("供应商：" + _vm._s(_vm.formData?.supplier?.title)),
                ]),
              ]),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "20", "show-word-limit": "" },
                        model: {
                          value: _vm.formData.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "title", $$v)
                          },
                          expression: "formData.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品品类", prop: "first_cid" } },
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled:
                            _vm.formData.first_cid == 0 || _vm.formData.cid == 0
                              ? false
                              : _vm.formData.cid_is_audit == 1 ||
                                _vm.formData.buy_way == 1 ||
                                _vm.formData.is_sales_cycle == 1,
                          options: _vm.options,
                          props: _vm.props,
                          filterable: "",
                        },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.category_tag_list.length > 0
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "品种/品牌",
                            prop: "category_tag_id",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled:
                                  _vm.formData.category_tag_id == 0
                                    ? false
                                    : _vm.formData.cid_is_audit == 1 ||
                                      _vm.formData.buy_way == 1 ||
                                      _vm.formData.is_sales_cycle == 1,
                                placeholder: "请选择品种/品牌",
                                filterable: "",
                              },
                              model: {
                                value: _vm.formData.category_tag_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "category_tag_id", $$v)
                                },
                                expression: "formData.category_tag_id",
                              },
                            },
                            _vm._l(
                              _vm.category_tag_list,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "采购员", prop: "buyer_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled:
                              _vm.formData.buyer_id == 0
                                ? false
                                : _vm.formData.cid_is_audit == 1 ||
                                  _vm.formData.buy_way == 1 ||
                                  _vm.formData.is_sales_cycle == 1,
                            placeholder: "请选择采购员",
                            filterable: "",
                          },
                          model: {
                            value: _vm.formData.buyer_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "buyer_id", $$v)
                            },
                            expression: "formData.buyer_id",
                          },
                        },
                        _vm._l(_vm.buyer_list, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.fullname, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格", prop: "spec" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "250",
                          disabled: _vm.formData.is_sales_cycle == 1,
                          "show-word-limit": "",
                          placeholder: "请输入规格",
                        },
                        model: {
                          value: _vm.formData.spec,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "spec", $$v)
                          },
                          expression: "formData.spec",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.type == 1 &&
                  _vm.formData.creator_type != 1 &&
                  _vm.formData.creator_type != 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "标签", prop: "promotion_tag_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择标签",
                                multiple: "",
                              },
                              model: {
                                value: _vm.promotion_tag_id,
                                callback: function ($$v) {
                                  _vm.promotion_tag_id = $$v
                                },
                                expression: "promotion_tag_id",
                              },
                            },
                            _vm._l(
                              _vm.promotion_tag_list,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.name,
                                    value: item.id + "",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.promotion_tag_id_change(
                                        $event,
                                        item
                                      )
                                    },
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("el-alert", {
                attrs: {
                  title: "请确保采购知晓更改事项，后台数据更改均会记录日志",
                  type: "warning",
                  closable: false,
                  "show-icon": "",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onHandleClose } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingSubmit },
                  on: { click: _vm.onHandleSubmit },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }