import axios from '../http';
const BASE_URL = process.env.VUE_APP_GENERAL_URL;

/**
 * 总控-商品管理-在售商品-查看变更记录
 * @param {*} params
 * @returns
 */
export function getProductChangeRecord(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${BASE_URL}/product/change/record/list`,
      data: params
    }
  );
};
